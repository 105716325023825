import { useEffect, useState } from "react";
import { Image } from "@chakra-ui/react";
import axios from "axios";
import Environment from "../../../environment";

const GetFile = ({ url, ...props }) => {
  const [image, setImage] = useState(null);

  const getToken = () => {
    const { body } = JSON.parse(localStorage.getItem(Environment.AUTH0_TOKEN));

    const { access_token: accessToken } = body;
    return `Bearer ${accessToken}`;
  };

  console.log(props, url);

  const uploadFiles = async () => {
    const headers = {
      Authorization: getToken(),
    };

    try {
      const config = {
        headers,
        responseType: "blob",
      };

      const resp = await axios.post(
        `${Environment.USER_URL}/get_files_from_blob`,
        { blobPath: url },
        config
      );
      setImage(resp?.data);
    } catch (err) {
      if (err.code !== "ERR_CANCELED") console.error(err);
    }
  };

  useEffect(() => {
    uploadFiles();
  }, [url]);

  return (
    image !== null && <Image src={URL.createObjectURL(image)} {...props} />
  );
};

export default GetFile;

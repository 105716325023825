import React, { useState } from "react";
import {
  Flex,
  Text,
  VStack,
  Button,
  HStack,
  useMediaQuery,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import generator from "generate-password";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import {
  useGetUserInfoQuery,
  useAddUserToOrgMutation,
  useGetOrgRolesQuery,
} from "../../state/API/HospitalApi";
import OrganisationDetails from "./OrganisationDetails";
import PersonalDetails from "./PersonalDetails";
import Loading from "../../Components/Loading/loading";
import UserCreatedConfirmation from "./UserCreatedConfirmation";
import Environment from "../../environment";

const AddMemberDashboard = () => {
  const user = useAuth0();
  const { data: userInfo, isLoading } = useGetUserInfoQuery({
    subClaim: user?.sub,
  });
  const { data: rolesInfo } = useGetOrgRolesQuery(
    {
      organizationId: userInfo?.organization?._id,
    },
    { skip: !userInfo }
  );
  console.log(rolesInfo);
  const [selectedRole, setSelectedRole] = useState("");
  const toast = useToast();
  const navigate = useNavigate();
  const [index, setIndex] = useState(1);
  const [userData, setUserData] = useState(null);
  const [isBlurState, setIsBlurState] = useState(false);
  const [addUser] = useAddUserToOrgMutation();
  const generatePassword = () => {
    const randomPassword = generator.generate({
      length: 12,
      strict: true,
      symbols: true,
      numbers: true,
      exclude: '()+_-=}{[]|:;"/?.><,`~',
    });
    return randomPassword;
  };
  const [isUserCreated, setIsUserCreated] = useState(false);
  const [inputData, setInputData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    contact: "",
    dob: "",
    userType: "",
    gender: "",
    designation: "",
    experience: "",
    qualification: "",
    registration: "",
    hospital: "",
    address: "",
    password: generatePassword(),
    signature: "",
    profilePic: "",
  });

  const getToken = () => {
    const { body } = JSON.parse(localStorage.getItem(Environment.AUTH0_TOKEN));

    const { access_token: accessToken } = body;
    return `Bearer ${accessToken}`;
  };

  const handleSubmit = async () => {
    setIsBlurState(true);
    const signatureForm = new FormData();
    const profilePicForm = new FormData();
    signatureForm.append("file", inputData.signature[0]);
    profilePicForm.append("file", inputData.profilePic[0]);

    const headers = {
      Authorization: getToken(),
    };

    const config = {
      headers,
    };

    const signatureUrls = await axios.post(
      `${Environment.USER_URL}/upload_signature`,
      signatureForm,
      config
    );

    const profilePicUrls = await axios.post(
      `${Environment.USER_URL}/upload_pic`,
      profilePicForm,
      config
    );

    try {
      const resp = await addUser({
        firstName: inputData.firstName.trimEnd(),
        lastName: inputData.lastName.trimEnd(),
        emailAddress: inputData.emailAddress.trimEnd().toLowerCase(),
        role: selectedRole,
        phoneNumber: inputData.contact,
        dateOfBirth: inputData.dob,
        gender: inputData.gender,
        designation: inputData.designation.trimEnd(),
        yearOfExperience: inputData.experience,
        highestDegree: inputData.qualification,
        address: inputData.address.trimEnd(),
        password: inputData.password.trimEnd(),
        signature: signatureUrls.data,
        profilePic: profilePicUrls.data,
        hospital: inputData.hospital,
        userType: "pathologist",
      }).unwrap();
      localStorage.setItem("role", selectedRole);
      if (resp === "User Created!") {
        setUserData(inputData);
        setIsUserCreated(true);
      }
      setInputData({
        firstName: "",
        lastName: "",
        emailAddress: "",
        contact: "",
        dob: "",
        gender: "",
        designation: "",
        experience: "",
        qualification: "",
        registration: "",
        address: "",
        password: generatePassword(),
        signature: "",
        profilePic: "",
      });
      setSelectedRole("");
      setIsBlurState(false);
    } catch (err) {
      toast({
        title: "Error",
        description: `Failed to add ${inputData.firstName}, try again!`,
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    }
  };

  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const [ifHeightLessthan800] = useMediaQuery("(max-height: 800px)");
  return (
    <Flex w="100%" direction="column">
      <Flex w="100%">
        <Flex minWidth="768px">
          <Flex
            h="100vh"
            w="100vw"
            background="light.800"
            zIndex="100"
            alignItems="flex-end"
            justifyContent="center"
            mb="70px"
          >
            <Flex
              h={ifHeightLessthan800 ? "89%" : "93%"}
              pos="absolute"
              bottom={0}
              w="75%"
              bgColor="#fff"
              p="3% 6% 0 6%"
              direction="column"
              overflowY="scroll"
              css={{
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "5px",
                  marginTop: "58px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#C4C4C4",
                },
              }}
            >
              {isBlurState ? <Loading /> : null}
              {isUserCreated ? (
                <UserCreatedConfirmation
                  userData={userData}
                  setIsUserCreated={setIsUserCreated}
                  setIndex={setIndex}
                  role={selectedRole}
                />
              ) : (
                <>
                  <Flex w="100%" justifyContent="space-between">
                    <VStack alignItems="flex-start">
                      <Text
                        fontSize={ifWidthLessthan1920 ? "16px" : "20px"}
                        color="light.1006"
                        fontWeight={600}
                      >
                        Add User
                      </Text>
                      <Text fontSize={ifWidthLessthan1920 ? "12px" : "14px"}>
                        Fill details below to create a new profile in your
                        organization
                      </Text>
                    </VStack>
                    <HStack color="light.1006">
                      <Button
                        w="150px"
                        bgColor="inherit"
                        border="1px solid rgba(27, 117, 188, 0.25)"
                        borderRadius="10px"
                        h="37px"
                        onClick={() => navigate("/dashboard/admin")}
                      >
                        Cancel
                      </Button>
                      {index === 2 ? (
                        <Button
                          w="150px"
                          borderRadius="10px"
                          h="37px"
                          bgColor="rgba(27, 117, 188, 0.25)"
                          disabled={
                            !inputData.firstName ||
                            !inputData.lastName ||
                            !inputData.emailAddress ||
                            !inputData.contact ||
                            selectedRole.length === 0 ||
                            !inputData.gender ||
                            !inputData.hospital ||
                            !inputData.password ||
                            !inputData.signature ||
                            !inputData.profilePic
                          }
                          onClick={handleSubmit}
                        >
                          Save
                        </Button>
                      ) : (
                        <Button
                          w="150px"
                          borderRadius="10px"
                          h="37px"
                          bgColor="rgba(27, 117, 188, 0.25)"
                          onClick={() => {
                            setIndex(index + 1);
                          }}
                        >
                          Next
                        </Button>
                      )}
                    </HStack>
                  </Flex>

                  {index === 1 ? (
                    <PersonalDetails
                      index={index}
                      setIndex={setIndex}
                      setInputData={setInputData}
                      inputData={inputData}
                      rolesInfo={rolesInfo}
                      selectedRole={selectedRole}
                      setSelectedRole={setSelectedRole}
                    />
                  ) : (
                    <OrganisationDetails
                      index={index}
                      setIndex={setIndex}
                      setInputData={setInputData}
                      inputData={inputData}
                    />
                  )}
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AddMemberDashboard;
